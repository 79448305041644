import React, { createContext, useContext, useEffect } from "react";
import { API, endpoints } from "../api";
import { useSetState } from "../hooks/useSetState";
import { capitalize, isEmpty } from "lodash";
import { useAuth } from "./Auth";
import { Helmet } from "react-helmet";

const initialState = {
  savedSearch: [],
  memberTypes: [],
  membershipStatus: [],
  memberGenders: [],
  promoters: [],
  events: [],
  countries: [],
  states: [],
  smsTemplates: [],
  fromEmailAddress: [],
  filteredFormData: {},
  recepientCount: 0,

  savedSearchOptions: [],
  memberTypesOptions: [],
  membershipStatusOptions: [],
  memberGendersOptions: [],
  promotersOptions: [],
  eventsOptions: [],
  divisionsOptions: [],
  countriesOptions: [],
  statesOptions: [],
  fromEmailAddressOptions: [],
  smsTemplatesOptions: [],

  activeTabIndex: 0,
};

const mapDropdownOptions = (data, key, text, value) =>
  (data || [])?.map((item, index) => {
    if (!key)
      return {
        key: index,
        text: capitalize(item),
        value: item,
      };
    return {
      key: item[key],
      text: item[text],
      value: value ? item[value] : item[key],
      title: item[text],
    };
  });

export const CommunicatorContext = createContext(initialState);

export const CommunicatorProvider = (props) => {
  const [state, setState] = useSetState(initialState);
  const { showToast } = useAuth();

  const { loginRedirectUrl, user } = useAuth()

  useEffect(() => {
    if (!user) {
      window.location = loginRedirectUrl
    }

  }, [user])

  useEffect(() => {
    fetchCommunicatorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const stateKeys = Object.keys({
      savedSearch: state.savedSearch,
      memberTypes: state.memberTypes,
      membershipStatus: state.membershipStatus,
      memberGenders: state.memberGenders,
      promoters: state.promoters,
      events: state.events,
      divisions: state.divisions,
      countries: state.countries,
      states: state.states,
      fromEmailAddress: state.fromEmailAddress,
      smsTemplates: state.smsTemplates,
    });

    stateKeys.forEach((value, index) => {
      if (!isEmpty(value)) {
        let optionsKeys = ["value", "name"];
        if (
          value === "savedSearch" ||
          value === "promoters" ||
          value === "smsTemplates"
        )
          optionsKeys = ["id", "name"];
        if (value === "countries") optionsKeys = ["id", "description"];
        if (value === "events") optionsKeys = ["id", "eventTitle"];
        if (value === "divisions") optionsKeys = ["id", "name"];
        if (value === "states") optionsKeys = ["id", "description"];
        if (value === "fromEmailAddress") optionsKeys = ["email", "name", "id"];
        if (value === "memberGenders") optionsKeys = [null];

        setState({
          [`${value}Options`]: mapDropdownOptions(state[value], ...optionsKeys),
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.savedSearch,
    state.memberTypes,
    state.membershipStatus,
    state.memberGenders,
    state.promoters,
    state.events,
    state.countries,
    state.states,
    state.smsTemplates,
    state.divisions,
  ]);

  async function fetchCommunicatorData() {
    const apiSavedSearch = API.get({
      route: endpoints.COMMUNICATOR_SAVED_SEARCH,
    });
    const apiMemberTypes = API.get({
      route: endpoints.COMMUNICATOR_MEMBER_TYPES,
    });
    const apiMembershipStatus = API.get({
      route: endpoints.COMMUNICATOR_MEMBERSHIP_STATUS,
    });
    const apiMemberGenders = API.get({
      route: endpoints.COMMUNICATOR_MEMBER_GENDERS,
    });
    const apiPromoter = API.get({
      route: endpoints.COMMUNICATOR_PROMOTER,
    });
    const apiCountries = API.get({
      route: endpoints.COMMUNICATOR_COUNTRIES,
    });
    const apiFromEmailAddress = API.get({
      route: endpoints.COMMUNICATOR_FROM_EMAIL_ADDRESS,
    });
    const apiSMSTemplate = API.get({
      route: endpoints.COMMUNICATOR_USER_SMS_TEMPLATE,
    });

    try {
      const [
        savedSearch,
        memberTypes,
        membershipStatus,
        memberGenders,
        promoters,
        countries,
        fromEmailAddress,
        smsTemplates,
      ] = await getFilterResult([
        apiSavedSearch,
        apiMemberTypes,
        apiMembershipStatus,
        apiMemberGenders,
        apiPromoter,
        apiCountries,
        apiFromEmailAddress,
        apiSMSTemplate,
      ]);

      setState({
        savedSearch,
        memberTypes,
        membershipStatus,
        memberGenders,
        promoters,
        countries,
        fromEmailAddress,
        smsTemplates,
      });
    } catch (error) {
      catchError(error);
    }
  }

  async function getFilterResult(apis) {
    const results = await Promise.all(apis);
    return results.map((result) => result.result);
  }

  function catchError(error, reject) {
    let text = error;

    if (error?.response?.data?.message, true) {
      text = error?.response?.data?.message;
    }

    const errors = error?.response?.data?.errors;

    if (errors) {
      text = Object.keys(errors)
        .reduce((acc, item) => [...acc, ...errors[item]], [])
        .join(" ");
    }
    showToast({
      text,
      type: "error",
    });
    reject && reject(error);
  }

  function successToast(message, isError) {
    showToast({
      text: message,
      type: isError ? "error" : '',
    });
  }

  const addFilter = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.post({
          route: endpoints.COMMUNICATOR_SAVED_SEARCH,
          data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        fetchFilter();
        successToast("Filter saved!");
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const fetchFilter = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.get({
          route: endpoints.COMMUNICATOR_SAVED_SEARCH,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        setState({
          savedSearch: result.result,
        });
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const deleteFilter = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.delete({
          route: `${endpoints.COMMUNICATOR_SEARCH_DELETE}/${id}`,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        successToast("Filter deleted!");
        fetchFilter();
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const updateFilter = (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.put({
          route: `${endpoints.COMMUNICATOR_SEARCH_DELETE}/${id}`,
          params: data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        successToast(result?.message)
        fetchFilter();
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const fetchEvents = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.get({
          route: `${endpoints.COMMUNICATOR_EVENT}/${id}/promoter`,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        setState({
          events: result.result,
        });
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const fetchDivisions = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.post({
          route: `${endpoints.COMMUNICATOR_DIVISION}`,
          params: data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        setState({
          divisions: result.result,
        });
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const fetchStates = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const results = await API.post({
          route: `${endpoints.COMMUNICATOR_COUNTRIES}/states`,
          params: data,
        });
        if (!results?.status) {
          reject(results)
          return successToast(results?.message, true)
        }
        setState({
          states: results.result,
        });
        resolve(results);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const fetchUserCount = (data, activeTabIndex) => {
    return new Promise(async (resolve, reject) => {
      try {
        let endPoint = `${endpoints.COMMUNICATOR_USER_COUNT_EMAIL}`;
        switch (activeTabIndex) {
          case 1:
            endPoint = `${endpoints.COMMUNICATOR_USER_COUNT_SMS}`;
            break;
          case 2:
            endPoint = `${endpoints.COMMUNICATOR_USER_COUNT_PORTAL_MESSAGE}`;
            break;
          default:
            break;
        }
        const result = await API.post({
          route: endPoint,
          params: data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        resolve(result);
        setState({
          recepientCount: result.result.count,
        });
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const sendEmail = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.post({
          route: `${endpoints.COMMUNICATOR_USER_SEND_EMAIL}`,
          params: data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        successToast("Email sent!");
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const sendSMS = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.post({
          route: `${endpoints.COMMUNICATOR_USER_SEND_SMS}`,
          params: data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        successToast("SMS sent!");

        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const sendTestSMS = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.post({
          route: `${endpoints.COMMUNICATOR_TEST_SMS}`,
          params: data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        successToast("Test SMS sent!");

        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const sendPortalMessage = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.post({
          route: `${endpoints.COMMUNICATOR_USER_SEND_PORTAL_MESSAGE}`,
          params: data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        successToast("Portal message sent!");
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const fetchPreviousSmsCommunications = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.get({
          route: `${endpoints.COMMUNICATOR_PREVIOUS_SMS_COMMUNICATIONS}`,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const fetchCurrentJobSummary = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.get({
          route: `${endpoints.COMMUNICATOR_CURRENT_JOB_SUMMARY}`,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const uploadAttachments = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.post({
          route: `${endpoints.COMMUNICATOR_UPLOAD_ATTACHMENTS}`,
          data,
        });
        if (!result?.status) {
          reject(result)
          return successToast(result?.message, true)
        }
        resolve(result);
      } catch (error) {
        catchError(error, reject);
      }
    });
  };

  const newState = {
    ...state,
    addFilter,
    deleteFilter,
    updateFilter,
    fetchEvents,
    fetchDivisions,
    fetchStates,
    fetchUserCount,
    fetchPreviousSmsCommunications,
    fetchCurrentJobSummary,
    sendEmail,
    sendSMS,
    sendTestSMS,
    sendPortalMessage,
    uploadAttachments,
    setCommunicatorState: setState,
  };

  return (
    <CommunicatorContext.Provider value={{ ...newState }}>
      <Helmet>
        <title>Communicator Portal</title>
      </Helmet>
      {user ? props.children : null}
    </CommunicatorContext.Provider>
  );
};

export const useCommunicator = () => useContext(CommunicatorContext);
