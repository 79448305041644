import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import PaymentForm from "./payment-form";
import { Error, LoaderSmall } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import strings from "../../utils/strings";

let timer;
const Subscription = () => {
  const [termsError, setTermsError] = useState(false);
  const { subscriptionData, userCheck, user, checkSubscription } = useAuth();
  const [termsChecked, setTermChecked] = useState(false);

  const params = useParams();
  const isUpdateCard = params?.type === 'new-card'

  const [waitingForWebhook, setWaitingForWebhook] = useState(false);
  const navigate = useNavigate();


  const goToSuccessPage = async (feedId) => {
    if (isUpdateCard) {
      return
    }
    try {
      const data = await checkSubscription();
      if (data?.oldSubscribed === true) {
        setWaitingForWebhook(true)
        timer = setInterval(() => goToSuccessPage(feedId), 1000)
        return
      }
      timer && clearInterval(timer)
      setWaitingForWebhook(false)
      navigate(
        `/membership-payment-success/${data?.membershipNumber}/${data?.expiryDate}/${feedId}`
      );
    } catch (e) {
      // setLoading(false);
    }
  }

  useEffect(() => {
    return () => timer && clearInterval(timer)
  }, [])


  const handleTermsCheck = (e) => {
    setTermChecked(e.target.checked);
    if (e.target.checked) {
      setTermsError(false);
    }
  };

  useEffect(() => {
    userCheck(() => { })
  }, [])

  if (!user) {
    return <Error error="Login is required" />
  }

  if (!user?.user?.country || !user?.user?.country_code) {
    return <div className="mt-5">
      <Error error={strings.noCountry} />
    </div>
  }

  if (waitingForWebhook) {
    return <div class="card font-xsss shadow-md w-100 border-0">
      <div class="card-body align-items-center p-5">
        <LoaderSmall />
        <p>
          Thank you for your subscription purchase! We're processing your request and awaiting a response from our system. Please refrain from refreshing the page, pressing back, or making a duplicate payment. Your patience is appreciated!
        </p>
      </div>
    </div>
  }

  const isIndia = user?.user?.country_code === "IN";


  return (
    <>
      {isUpdateCard ? null : <div class="card font-xsss shadow-xss w-100 border-0 m-0 p-4 mb-3 pt-0 pb-1">
        <div class="card-body align-items-center p-0">
          <div className="agreement">
            <div class="agreement-title">
              <i class="fa-sharp fa-solid fa-file font-xxl text-current"></i>
              <h4 translate="ENTER_EVENT_DRUG_TITLE" class="mt-2">
                Terms &amp; Conditions
              </h4>
            </div>

            <p translate="PROFILE_TERMS_SUBTITLE" className="ng-scope">
              In joining as a Premium Member, I hereby acknowledge and agree as
              follows:
            </p>
            <ol
              ng-class="(langDirection==='rtl') ? 'text-right' : 'text-left'"
              className="text-left"
            >
              <li>
                <span className="text">
                  <span translate="PROFILE_TERMS1" className="ng-scope">
                    I have been drug-free for a minimum of 5 years.
                  </span>
                </span>
              </li>
              <li>
                <span className="text ng-scope" translate="PROFILE_TERMS2">
                  Agree to be drug tested at any time, and any number of times.
                  A refusal to supply a sample is treated as a positive test.
                </span>
              </li>
              <li>
                <span className="text ng-scope" translate="PROFILE_TERMS3">
                  iCompete Natural (ICN), National and International Federations
                  have jurisdiction to impose sanctions.
                </span>
              </li>
              <li>
                <span className="text ng-scope" translate="PROFILE_TERMS4">
                  Decisions of iCompete Natural (ICN) shall be final and
                  enforceable, and that I will not bring any claim, arbitration,
                  lawsuit or litigation in any other court or tribunal.
                </span>
              </li>
              <li>
                <span className="text ng-scope" translate="PROFILE_TERMS5">
                  I have read and understand this Acknowledgement and Agreement.
                </span>
              </li>
            </ol>
            <div
              className={`agreement-checkbox ${termsError ? "terms-error" : ""
                }`}
            >
              <input
                type="checkbox"
                className="mr-1"
                id="conditions"
                onChange={handleTermsCheck}
              />
              <label
                htmlFor="conditions"
                translate="ENTER_EVENT_DRUG_AGREE_LABEL"
                className="ng-scope"
              >
                {" "}
                I agree to the above conditions
              </label>
            </div>
          </div>
        </div>
      </div>}
      <div class="card font-xsss shadow-xss w-100 border-0 p-4 mb-3">
        <div class="card-body align-items-center p-0 relative">
          <i class="fa-solid fa-xmark cross-icon" onClick={() => navigate(-1)}></i>
          <h2>{isUpdateCard ? 'Enter new card' : 'Payment Info'}</h2>
          <img className="stripe-image" src="./images/stripe.png" />
          {isUpdateCard ? null : <h3 className="mt-3 mb-3">
            Amount {subscriptionData?.plan?.sign}{" "}
            {subscriptionData?.plan?.amount}
          </h3>}
          <PaymentForm
            setTermsError={setTermsError}
            termsChecked={termsChecked}
            goToSuccessPage={goToSuccessPage}
          />
          {subscriptionData?.plan?.subscription_payment ? (
            isUpdateCard ? null : <h2 className="mb-4 text-red">This membership is set to auto-renew in 365 days. You can cancel the auto-renew anytime.</h2>
          ) : null}

          {isIndia ? <div className="text-lg">
            Use the following details to make the payment:
            <p className="text-red">(Within 24 hours you will receive your Premium Membership email)</p>

            <div className="p-2">
              <p className="m-0">Account Number: 1122155000230173</p>
              <p className="m-0">IFSC Code: KVBL0001122</p>
              <p className="m-0">Account Type: Saving</p>
              <p className="m-0">Account Name: Jerry Jacob Sundaram</p>
              <p className="m-0">Branch: Combatore R S Puram.</p>
              <p className="m-0">-------</p>
              <p className="m-0">GPay: 9597556025 / jjerry55@okaxis</p >
              <p className="m-0">Amazon Pay: 9597556025@apl</p >
              <p className="m-0">PhonePe: jjerry55@ybl</p >
              <p className="m-0">BHIM: 9597556025@upi</p >
            </div>

            <p className="text-red mt-2">Reference Number: Use your 5-digit Membership Number (found in MY PROFILE). </p>
          </div> : null}

        </div>
      </div >
    </>
  );
};

export default Subscription;
